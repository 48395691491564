<template>
  <v-container id="showMembers" class="bgMezzo mx-0" fluid>
    <v-row class="mt-11 pt-10 mx-0" id="clubeVip">
      <v-col cols="12" md="10" class="d-flex justify-center align-center">
        <v-text-field
          hide-details
          v-if="intBreakPoint >= 2"
          light
          v-model="search"
          label="Procurar por nome, cpf, email..."
          outlined
          style="width: 100% !important"
        />
        <v-text-field
          v-if="intBreakPoint < 2"
          dark
          v-model="search"
          label="Procurar por nome, cpf, email..."
          outlined
        />
      </v-col>
      <v-col
        cols="12"
        md="2"
        style=""
        class="d-flex justify-center align-center"
      >
        <v-btn class="btnExport" @click="exportDialog = true">
          <v-icon class="white--text" color="white">mdi-file-excel</v-icon>
          Exportar
        </v-btn>
        <v-icon
          size="2.5rem"
          color="#000"
          @click="dialogUpdateCashback = !dialogUpdateCashback"
          >mdi-cog-outline</v-icon
        >
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col>
        <v-data-table
          :headers="headers"
          :items="users"
          :items-per-page="15"
          class="elevation-1"
          item-key="celular"
          :search="search"
          :loading="loading"
          loading-text="Carregando... Um instante por favor."
        >
          <template v-slot:item.date_created="{ item }">
            {{ item.date_created | formatISODateUtc }}
          </template>

          <template v-slot:[`item.historico`]="{ item }">
            <v-btn class="ml-1 btnAction" x-small @click="dialogUsuario(item)">
              Histórico
            </v-btn>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              class="ml-1 btnAction"
              x-small
              @click="voucherDialog(item)"
              :disabled="item.cooldownOver ? false : true"
            >
              <v-icon small class="mr-2"> mdi-plus-circle </v-icon>
              Marcar Uso
            </v-btn>
          </template>

          <template v-slot:[`item.saldo`]="{ item }">
            <v-btn class="ml-1 btnAction" x-small @click="depositDialog(item)">
              <v-icon small class=""> mdi-plus </v-icon>
            </v-btn>
          </template>

          <template v-slot:[`item.despesa`]="{ item }">
            <v-btn class="ml-1 btnAction" x-small @click="withdrawDialog(item)">
              <v-icon small class=""> mdi-minus </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      :width="`${intBreakPoint >= 2 ? '60vw' : '80 vw'}`"
    >
      <ClienteHistorico
        :selectedUser="selectedUser"
        :selectedUserDesconto="selectedUserDesconto"
        :selectedUserSaldo="selectedUserSaldo"
        :selectedUserDespesa="selectedUserDespesa"
        @close="dialog = false"
      />
    </v-dialog>

    <v-dialog content-class="config-dialog" v-model="dialogUpdateCashback">
      <v-card class="config-card">
        <v-icon class="close-dialog" @click="dialogUpdateCashback = false"
          >mdi-close</v-icon
        >
        <v-card-title>Porcentagem de cashback:</v-card-title>
        <v-card-subtitle
          >Atual: {{ cashbackFixo.cashback * 100 }}%</v-card-subtitle
        >
        <div style="height: 40px; margin-left: 20px">
          <input
            v-model="newCashbackRate"
            class="dialog-input"
            placeholder="Alterar porcentagem"
            type="number"
            outlined
          />
          <v-btn @click="updateCashbackRate" class="dialog-btn">Enviar</v-btn>
        </div>
      </v-card>
    </v-dialog>

    <DialogActions
      :dialogVisible="showDialogActions"
      :title="dialogActionsFields.title"
      :subtitle="dialogActionsFields.subtitle"
      :hasValue="dialogActionsFields.hasValue"
      :placeholder="dialogActionsFields.placeholder"
      :inputType="dialogActionsFields.inputType"
      :action="dialogActionsFields.action"
      :textfield="dialogActionsFields.textfield"
      @discount="createVoucher"
      @deposit="mezzoCoinDeposit"
      @withdraw="mezzoCoinWithdraw"
      @close="handleDialogActionsClose"
    >
    </DialogActions>

    <v-dialog
      v-model="exportDialog"
      :width="intBreakPoint >= 2 ? '60vw' : '80vw'"
    >
      <DialogExport />
    </v-dialog>
  </v-container>
</template>

<style scoped>
.btnExport {
  background-color: #6ebf68 !important;
  color: #fff !important;
}

.coin-config {
  padding: 0px 20px;
  font-size: 2rem;
  cursor: pointer;
}

.config-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.dialog-input {
  border: 1px solid #77777770;
  border-radius: 5px;
  height: 100%;
  margin-right: 10px;
  padding-left: 10px;
}

.close-dialog {
  position: absolute;
  width: 100%;
  left: 45%;
  top: 5px;
}

@media (min-device-width: 360px) and (max-device-width: 853px) {
  .v-data-table__mobile-row {
    height: initial;
    min-height: 48px;
    display: grid !important;
    padding: 11px 10px !important;
  }

  .v-data-table__mobile-row {
    align-items: center;
    display: grid !important;
    justify-content: space-between;
    padding: 15px 10px !important;
  }
}

.btnAction {
  background-color: #6ebf68 !important;
  font-size: 13px !important;
  color: #fff !important;
  padding: 15px !important;
  border-radius: 50px !important;
}

.voucher {
  background-color: #72c5d3;
  color: white;
  padding: 3px;
}

.bgMezzo {
  background: rgb(52, 0, 15);
  background: linear-gradient(
    180deg,
    rgba(52, 0, 15, 1) 0%,
    rgba(142, 31, 63, 1) 5%,
    rgba(255, 255, 255, 1) 10%,
    rgba(255, 255, 255, 1) 100%
  );
  min-height: 100vh;
}
</style>

<script>
import axios from 'axios';
import DialogActions from '../components/DialogActions.vue';
import ClienteHistorico from '../components/ClienteHistorico.vue';
import DialogExport from '../components/DialogExport.vue';
import * as dayjs from 'dayjs';

export default {
  name: 'Cashback',
  components: {
    DialogActions,
    ClienteHistorico,
    DialogExport
  },
  data: () => ({
    select: 'Clube VIP',
    selectedUser: null,
    selectedUserDesconto: null,
    selectedUserSaldo: null,
    selectedUserDespesa: null,
    mostRecentTransaction: null,
    search: null,
    dialogSelectedUser: false,
    dialog: false,
    btnMarcarUso: true,
    latestMeal: null,
    cooldownOver: false,
    exportDialog: false,
    cashbackFixo: {
      cashback: 0
    },
    newCashbackRate: null,
    dialogUpdateCashback: false,
    showDialogActions: null,
    dialogActionsFields: {},
    headers: [
      { text: 'Data', value: 'date_created' },
      { text: 'Nome', value: 'full_name' },
      { text: 'Empresa', value: 'company.name' },
      { text: 'Saldo', value: 'balance' },
      { text: 'CPF', value: 'document', width: '130px', sortable: false },
      {
        text: 'Histórico',
        align: 'center',
        value: 'historico',
        sortable: false
      },
      {
        text: 'Marcar Uso',
        align: 'center',
        value: 'actions',
        sortable: false
      },
      {
        text: 'Lançar saldo',
        align: 'center',
        value: 'saldo',
        sortable: false
      },
      {
        text: 'Lançar despesa',
        align: 'center',
        value: 'despesa',
        sortable: false
      }
    ],
    users: [],
    loading: false
  }),
  // created() {
  //   this.loading = true;
  // fetch(
  //   "https://gestao.abare.cloud/items/leads?filter[id_formulario][_eq]=39&sort=-date_created&limit=3000",
  //   {
  //     headers: {
  //       Authorization: "Bearer QaIIzDCLgYdRQKxLVhLpEeQv81VnBw1awkOc",
  //     },
  //   }
  // )
  //   .then((response) => response.json())
  //   .then((data) => {
  //     this.users = data.data;
  //     this.loading = false;
  //   });
  // },

  computed: {
    intBreakPoint() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 0;
        case 'sm':
          return 1;
        case 'md':
          return 2;
        case 'lg':
          return 3;
        case 'xl':
          return 4;
        default:
          return 0;
      }
    }
  },
  beforeMount() {
    this.getCashbackRate();
    this.getUsers();
  },
  methods: {
    submitInput() {
      // Aqui você pode emitir um evento com o valor do input para o componente pai ou realizar alguma ação
      this.$emit('input-submitted', this.inputValue);
      // Limpe o valor do input
      this.inputValue = '';
    },
    async updateCashbackRate() {
      const newCashbackRate = this.newCashbackRate;
      switch (true) {
        case newCashbackRate == 0:
          this.$notify({
            group: 'foo',
            type: 'error',
            title: 'Erro',
            text: 'Insira um valor maior que zero',
            pauseOnHover: true
          });
          break;
        case newCashbackRate < 0:
          this.$notify({
            group: 'foo',
            type: 'error',
            title: 'Erro',
            text: 'Insira um valor positivo para a despesa',
            pauseOnHover: true
          });
          break;
        case newCashbackRate > 100:
          this.$notify({
            group: 'foo',
            type: 'error',
            title: 'Erro',
            text: 'Valor não pode ser maior que 100',
            pauseOnHover: true
          });
          break;
        case newCashbackRate > 0 && newCashbackRate <= 100:
          {
            const {
              data: { data }
            } = await axios.patch(`/items/companies/${this.cashbackFixo.id}`, {
              cashback: parseFloat(newCashbackRate) / 100
            });

            this.cashbackFixo = data;
            this.newCashbackRate = null;
          }
          break;
        default:
          break;
      }
    },
    async getCashbackRate() {
      try {
        const {
          data: { data }
        } = await axios.get('/items/companies?filter[name][_eq]=Cashback Fixo');

        this.cashbackFixo = data[0];
      } catch (error) {
        console.log(error);
      }
    },

    async exportarLeads() {
      try {
        const [leadsResponse, transactionsResponse] = await Promise.all([
          axios.get('/exports/users', { responseType: 'blob' }),
          axios.get('/exports/transactions', { responseType: 'blob' })
        ]);

        const usersData = leadsResponse.data;
        const transactionsData = transactionsResponse.data;

        const urlUsers = window.URL.createObjectURL(usersData);
        const urlTransactions = window.URL.createObjectURL(transactionsData);

        const linkUsers = document.createElement('a');
        linkUsers.href = urlUsers;
        linkUsers.setAttribute('download', 'usuarios.xlsx');
        document.body.appendChild(linkUsers);
        linkUsers.click();
        document.body.removeChild(linkUsers);

        const linkTransactions = document.createElement('a');
        linkTransactions.href = urlTransactions;
        linkTransactions.setAttribute('download', 'transacoes.xlsx');
        document.body.appendChild(linkTransactions);
        linkTransactions.click();
        document.body.removeChild(linkTransactions);
      } catch (error) {
        console.error(error);
      }
    },

    async dialogUsuario(user) {
      // await this.getUsers();
      this.dialog = true;
      this.selectedUser = user;
      this.selectedUserDesconto = this.selectedUser.transactions
        .filter((el) => el.status === 'discount')
        .sort((a, b) => new Date(b.date_created) - new Date(a.date_created));
      this.selectedUserSaldo = this.selectedUser.transactions
        .filter((el) => el.status === 'deposit')
        .sort((a, b) => new Date(b.date_created) - new Date(a.date_created));
      this.selectedUserDespesa = this.selectedUser.transactions
        .filter((el) => el.status === 'withdraw')
        .sort((a, b) => new Date(b.date_created) - new Date(a.date_created));
    },
    async getMostRecentTransaction(user) {
      this.selectedUser = user;
      const selectedUserDesconto = this.selectedUser.transactions
        .filter((el) => el.status === 'discount')
        .sort((a, b) => new Date(b.date_created) - new Date(a.date_created));

      const selectedUserSaldo = this.selectedUser.transactions
        .filter((el) => el.status === 'deposit')
        .sort((a, b) => new Date(b.date_created) - new Date(a.date_created));

      const selectedUserDespesa = this.selectedUser.transactions
        .filter((el) => el.status === 'withdraw')
        .sort((a, b) => new Date(b.date_created) - new Date(a.date_created));

      if (
        selectedUserDesconto.length > 0 ||
        selectedUserSaldo.length > 0 ||
        selectedUserDespesa.length > 0
      ) {
        // Combine all the arrays into one and sort them by date_created
        const allTransactions = [
          ...selectedUserDesconto,
          ...selectedUserSaldo,
          ...selectedUserDespesa
        ];
        allTransactions.sort(
          (a, b) => new Date(b.date_created) - new Date(a.date_created)
        );

        // The first element will be the most recent transaction
        user.latestMeal = allTransactions[0].date_created;
        this.mostRecentTransaction = this.converterData(
          allTransactions[0].date_created
        );
        user.mostRecentTransaction = this.mostRecentTransaction;
      } else {
        this.mostRecentTransaction = 'Nenhuma transação';
        user.mostRecentTransaction = this.mostRecentTransaction;
      }

      this.isCooldownOver(user);
    },
    isCooldownOver(user) {
      // Define the target date and time
      const targetDateTime = new Date(user.latestMeal);

      // Get the current date and time
      const currentDateTime = new Date();

      // Calculate the time difference in milliseconds
      const timeDifference = currentDateTime - targetDateTime;

      // Convert milliseconds to hours
      const hoursDifference = timeDifference / (1000 * 60 * 60);

      if (isNaN(hoursDifference)) {
        user.cooldownOver = true;
        return;
      }

      // Check if it has been 1 hours
      if (hoursDifference >= 1) {
        user.cooldownOver = true;
        return;
      }
    },
    async sortDateCreated(dates) {
      dates.sort((a, b) => new Date(b.date_created) - new Date(a.date_created));
      return dates;
    },
    converterData(date) {
      const dateFormatted = dayjs(date).format('DD/MM/YYYY');
      return dateFormatted;
    },
    async getUsers() {
      try {
        this.loading = true;
        const {
          data: { data }
        } = await axios.get(
          '/users?fields=*,transactions.*,company.id,company.name&limit=-1&filter[role][_eq]=10cacf12-2e2c-416d-8d52-63baaea8baea'
        );

        data.forEach(async (user) => {
          user.full_name = `${user.first_name} ${user.last_name}`;
          user.data_criacao = this.converterData(user.date_created);

          let formatedBalance = Math.trunc(user.balance * 100) / 100

          user.balance = formatedBalance.toFixed(2);
          user.transactions = await this.sortDateCreated(user.transactions);
        });

        // data.sort((a, b) => {
        //   const nameA = a.full_name.toUpperCase();
        //   const nameB = b.full_name.toUpperCase();

        //   if (nameA < nameB) {
        //     return -1;
        //   }
        //   if (nameA > nameB) {
        //     return 1;
        //   }
        //   return 0;
        // });

        this.users = data;

        this.users.forEach((user) => {
          this.getMostRecentTransaction(user);
        });

        this.loading = false;
      } catch (error) {
        console.log(error);

        this.loading = false;
        this.$router.push({ name: 'login' });
      }
    },

    getRestantes(u) {
      return (
        (this.select == 'Clube VIP' ? 1 : 10) -
        (u != null ? u.split(',').length : 0)
      );
    },
    dtUsos(u) {
      return u != null ? u : [];
    },
    openWhatsapp(intTelefone) {
      let strMensagem = 'Olá, estamos te esperando no Mezzo Bar ';
      window.open(
        'https://api.whatsapp.com/send?phone=' +
          intTelefone +
          '&text=' +
          encodeURIComponent(strMensagem)
      );
    },
    voucherDialog(user) {
      this.selectedUser = user;

      this.btnMarcarUso = false;
      this.dialogActionsFields = {
        title: `Uso para ${user.first_name} ${user.last_name}:`,
        subtitle: `${user.company ? user.company.name : ''}`,
        textfield: false,
        placeholder: 'Comanda',
        inputType: 'text',
        action: 'discount'
      };
      this.showDialogActions = true;
    },
    async createVoucher(tabCode) {
      try {
        const {
          data: { data }
        } = await axios.post(`/mezzo-coin/discount`, {
          user_id: this.selectedUser.id,
          tab_code: tabCode
        });

        const foundUser = this.users.find(
          (el) => el.id === this.selectedUser.id
        );
        if (foundUser) {
          foundUser.transactions = foundUser.transactions || [];
          foundUser.transactions.push({
            status: 'discount',
            user: this.selectedUser.id,
            date_created: data.transaction_date,
            tab_code: tabCode
          });
          foundUser.transactions = await this.sortDateCreated(
            foundUser.transactions
          );

          this.getMostRecentTransaction(foundUser);
        }
        await this.getUsers();
        this.handleDialogActionsClose();
      } catch (error) {
        console.log(error);
        alert('Erro ao marcar uso');
        this.handleDialogActionsClose();
      }
    },

    depositDialog(user) {
      try {
        this.selectedUser = user;
        this.validadeUserFields();
        this.dialogActionsFields = {
          title: `Depósito para ${user.first_name} ${user.last_name}:`,
          subtitle: `Valor da da compra para cashback: `,
          placeholder: 'Valor da compra',
          textfield: true,
          hasValue: true,
          inputType: 'number',
          action: 'deposit'
        };
        this.showDialogActions = true;
      } catch (error) {
        alert(error.message);
      }
    },
    validadeUserFields() {
      try {
        const requiredFields = [
          { key: 'first_name', display: 'nome' },
          // { key: 'last_name', display: 'sobrenome' },
          { key: 'phone', display: 'telefone' },
          { key: 'document', display: 'CPF' },
          { key: 'password', display: 'senha' }
          // { key: 'birthday', display: 'data de nascimento' }
        ];

        for (const field of requiredFields) {
          const key = field.key;
          const display = field.display;

          if (!this.selectedUser[key]) {
            throw new Error(`Campo ${display} não foi preenchido.`);
          }
        }
      } catch (error) {
        throw new Error(error.message);
      }
    },
    async mezzoCoinDeposit(tabCode, value) {
      try {
        const {
          data: { data }
        } = await axios.post(`/mezzo-coin/deposit`, {
          user_id: this.selectedUser.id,
          tab_code: tabCode,
          value
        });

        const foundUser = this.users.find(
          (el) => el.id === this.selectedUser.id
        );
        if (foundUser) {
          foundUser.balance = data.balance;
          foundUser.transactions = foundUser.transactions || [];
          foundUser.transactions.push({
            status: 'deposit',
            user: this.selectedUser.id,
            tab_code: tabCode,
            value: data.deposit_value,
            date_created: data.transaction_date
          });
          foundUser.transactions = await this.sortDateCreated(
            foundUser.transactions
          );
        }
        this.handleDialogActionsClose();
      } catch (error) {
        console.log(error);
        alert('Erro ao lançar saldo');
        this.handleDialogActionsClose();
      }
    },
    withdrawDialog(user) {
      try {
        this.selectedUser = user;
        this.validadeUserFields();
        this.dialogActionsFields = {
          title: `Saque para ${user.first_name} ${user.last_name}:`,
          subtitle: `Valor para debitar:`,
          placeholder: 'Valor do débito',
          textfield: true,
          hasValue: true,
          inputType: 'number',
          action: 'withdraw'
        };
        this.showDialogActions = true;
      } catch (error) {
        alert(error.message);
      }
    },
    async mezzoCoinWithdraw(tabCode, value) {
      try {
        const {
          data: { data }
        } = await axios.post(`/mezzo-coin/withdraw`, {
          user_id: this.selectedUser.id,
          value,
          tab_code: tabCode
        });

        const foundUser = this.users.find(
          (el) => el.id === this.selectedUser.id
        );
        if (foundUser) {
          foundUser.balance = data.balance;
          foundUser.transactions = foundUser.transactions || [];
          foundUser.transactions.push({
            status: 'withdraw',
            user: this.selectedUser.id,
            tab_code: tabCode,
            value: data.withdraw_value,
            date_created: data.transaction_date
          });
          foundUser.transactions = await this.sortDateCreated(
            foundUser.transactions
          );
        }
        this.handleDialogActionsClose();
      } catch (error) {
        const errorMessage =
          error.response?.data?.errors[0]?.message ?? error.message;
        console.log(errorMessage);
        if (errorMessage === 'Value cannot be greater than the balance') {
          alert('Valor de despesa não pode ser maior que saldo');
        } else {
          alert('Erro ao lançar despesa');
        }
        this.handleDialogActionsClose();
      }
    },
    handleDialogActionsClose() {
      this.showDialogActions = false;
      // this.selectedUser.cooldownOver = false;
    }
    // criarVoucher(l) {
    //   if (confirm("Você deseja marcar uso de " + l.lead.nome)) {
    //     let dtNowHuman =
    //       new Date().toISOString().slice(0, 11) +
    //       " " +
    //       new Date().toLocaleTimeString("pt-BR");
    //     if (l.lead.usos === undefined) {
    //       l.lead.usos = [dtNowHuman];
    //     } else {
    //       l.lead.usos.push(dtNowHuman);
    //     }
    //     l.lead.dt_uso = dtNowHuman;
    //     fetch("https://gestao.abare.cloud/items/leads/" + l.id, {
    //       method: "PATCH", // or 'PUT'
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: "Bearer QaIIzDCLgYdRQKxLVhLpEeQv81VnBw1awkOc",
    //       },
    //       body: JSON.stringify(l),
    //     })
    //       .then((response) => response.json())
    //       .then((data) => {
    //         if (data.data.id) {
    //           window.alert(
    //             "Marcado uso para" + l.lead.nome + " em " + dtNowHuman
    //           );
    //           this.$forceUpdate();
    //         }
    //       });
    //   }
    // },
  }
};
</script>

