<template>
  <v-dialog :value="dialogVisible" @input="closeDialog" width="auto" persistent style="overflow-x: hidden;">
    <v-card class="d-flex flex-column align-start justify-center "
      style="padding-bottom: 4rem; overflow-x: hidden; min-width: 20rem; word-wrap: break-word;">
      <button class="align-self-end">
        <v-icon @click="closeDialog">mdi-close</v-icon>
      </button>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-subtitle>{{ subtitle }}</v-card-subtitle>
      <div class="d-flex" style="height: 40px; margin-left: 20px">
        <input v-if="textfield" v-model="tabCode" class="dialog-input" placeholder="Digite a Mesa/Venda" type="text"
          outlined />
        <input v-if="hasValue" v-model="value" class="dialog-input" :placeholder="placeholder" :type="inputType"
          outlined />
        <v-btn @click="submitInput" class="dialog-btn">Enviar</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    action: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    hasValue: {
      type: Boolean,
      default: false
    },
    inputType: {
      type: String,
      default: 'text'
    },
    textfield: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tabCode: '',
      value: 0
    };
  },
  methods: {
    submitInput() {
      if (this.action === 'discount') {
        this.$emit('discount', this.tabCode);
      } else if (this.action === 'deposit') {
        this.$emit('deposit', this.tabCode, this.value);
      } else if (this.action === 'withdraw') {
        this.$emit('withdraw', this.tabCode, this.value);
      }

      this.tabCode = '';
      this.value = 0;
    },
    closeDialog() {
      this.$emit('close');
    }
  }
};
</script>

<style>
.config-card {
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.dialog-input {
  border: 1px solid #77777770;
  border-radius: 5px;
  /* height: 100%; */
  margin-right: 10px;
  padding-left: 10px;
}

.close-dialog {
  position: absolute;
  width: 100%;
  left: 45%;
  top: 5px;
}
</style>
