<template>
  <v-card class="d-flex align-start justify-space-between pa-8">
    <div class="d-flex flex-column align-start justify-start">
      <h2>Exportar dados</h2>
      <v-select
        placeholder="Tipo de Arquivo"
        filled
        :items="types"
        item-value="value"
        v-model="chosenType"
      ></v-select>
      <v-checkbox v-model="translate" label="Traduzir?"></v-checkbox>
    </div>
    <div
      class="d-flex flex-column align-start justify-start"
      style="width: 500px; max-width: 550px"
    >
      <h2>Dados desejados:</h2>
      <v-radio-group v-model="usersOrTransactions" row>
        <v-radio label="Usuários" value="users"></v-radio>
        <v-radio label="Usuários Inativos" value="inactive_users"></v-radio>
        <v-radio label="Transações" value="transactions"></v-radio>
        <div v-if="usersOrTransactions === 'inactive_users'">
          <v-radio-group v-model="interval">
            <div class="d-flex flex-column">
              <div><h4>Período:</h4></div>
              <div class="d-flex align-center">
                <v-radio label="30 Dias" value="30"></v-radio>
                <v-radio label="45 Dias" value="45"></v-radio>
                <v-radio label="60 Dias" value="60"></v-radio>
                <v-radio label="Customizado" value="custom"></v-radio>
                <div>
                  <input
                    type="number"
                    v-if="interval === 'custom'"
                    placeholder="Ex: 90"
                    style="max-width: 100px; border-bottom: 1px solid gray"
                    v-model="customInterval"
                  />
                </div>
              </div>
            </div>
          </v-radio-group>
        </div>
      </v-radio-group>
      <v-btn @click="exportChosenData">Exportar</v-btn>
    </div>
  </v-card>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
export default {
  name: 'DialogExport',
  data() {
    return {
      types: [
        {
          text: 'CSV',
          value: 'csv'
        },
        {
          text: 'XLSX',
          value: 'xlsx'
        },
        {
          text: 'JSON',
          value: 'json'
        },
        {
          text: 'XML',
          value: 'xml'
        },
        {
          text: 'YAML',
          value: 'yaml'
        }
      ],
      chosenType: '',
      translate: true,
      customInterval: '',
      interval: '',
      intervalDate: '',
      usersOrTransactions: 'users',
      company_id: '08d8bf64-e17f-43bf-8e75-0bac98ce38f7'
    };
  },
  methods: {
    closeDialog() {
      this.$emit('close');
    },
    downloadFile(url, fileName) {
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async exportChosenData() {
      try {
        if (this.usersOrTransactions === 'inactive_users') {
          if (!this.validateInterval()) {
            return;
          }
        }

        if (!this.chosenType) {
          alert('Selecione um tipo de arquivo!');
          return;
        }

        const params = {
          file: this.usersOrTransactions,
          export_type: this.chosenType,
          translate_headers: this.translate,
          company_id: this.company_id,
          interval: this.intervalDate
        };

        const response = await axios.get(`/exports`, {
          params,
          responseType: 'blob'
        });

        const url = window.URL.createObjectURL(response.data);
        const fileName = `${this.usersOrTransactions}.${this.chosenType}`;

        this.downloadFile(url, fileName);
      } catch (error) {
        console.error(error);
      }
    },

    validateInterval() {
      if (!this.interval) {
        alert('Selecione um intervalo!');
        return false;
      }

      if (this.interval === 'custom' && !this.customInterval) {
        alert('Preencha o intervalo personalizado!');
        return false;
      }

      let intervalTemp =
        this.interval === 'custom' ? this.customInterval : this.interval;

      this.intervalDate = dayjs()
        .subtract(intervalTemp, 'day')
        .format('YYYY-MM-DD');

      const isValidDate = dayjs(this.intervalDate).isValid();

      if (!isValidDate) {
        alert('Intervalo inválido!');
        return false;
      }

      return true;
    }
  }
};
</script>

<style scoped></style>

