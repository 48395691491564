<template>
  <v-card class="pa-4">
    <v-row>
      <v-col cols="12" md="12" class="d-flex align-center justify-end">
        <v-icon @click="closeDialog">mdi-close</v-icon>
      </v-col>
    </v-row>
    <v-row style="width: 100%" justify="start">
      <v-col cols="12" md="12" class="d-flex flex-column justify-center">
        <v-card style="padding: 15px; background-color: #570c22">
          <div style="gap: 1rem" class="d-flex align-center">
            <h3 style="color: #fff; font-family: poppins; font-weight: 600">
              Nome:
            </h3>
            <p style="color: #fff; font-family: poppins; margin-bottom: 0px">
              {{ selectedUser.full_name }}
            </p>
          </div>
          <div style="gap: 1rem" class="d-flex align-center">
            <h3 style="color: #fff; font-family: poppins; font-weight: 600">
              Empresa:
            </h3>
            <p style="color: #fff; font-family: poppins; margin-bottom: 0px">
              {{ selectedUser.company ? selectedUser.company.name : null }}
            </p>
          </div>
          <div style="gap: 1rem" class="d-flex align-center">
            <h3 style="color: #fff; font-family: poppins; font-weight: 600">
              CPF:
            </h3>
            <p style="color: #fff; font-family: poppins; margin-bottom: 0px">
              {{ selectedUser.document }}
            </p>
          </div>
          <div style="gap: 1rem" class="d-flex align-center">
            <h3 style="color: #fff; font-family: poppins; font-weight: 600">
              Telefone:
            </h3>
            <p style="color: #fff; font-family: poppins; margin-bottom: 0px">
              {{ selectedUser.phone }}
            </p>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <div class="d-flex align-center justify-space-around mt-10">
      <div
        class="d-flex flex-column align-center"
        style="width: 33%; padding: 20px"
      >
        <h4 style="font-family: poppins; color: #570c22">
          Tipo de transação: Desconto
        </h4>
        <div style="height: 20rem; overflow-y: auto">
          <v-card
            style="box-shadow: none"
            v-for="(discount, index) in selectedUserDesconto"
            :key="index"
          >
            <v-card-text
              style="color: black; margin-bottom: ; border: 1px solid #570c22"
            >
              <p>Data: {{ converterData(discount.date_created) }}</p>
            </v-card-text>
          </v-card>
        </div>
      </div>

      <div
        class="d-flex flex-column align-center"
        style="width: 33%; padding: 20px; background-color: #f0f0f0"
      >
        <h4 style="font-family: poppins; color: #570c22">
          Tipo de transação: Depósito
        </h4>
        <div style="height: 20rem; overflow-y: auto">
          <v-card
            style="box-shadow: none"
            v-for="(deposit, index) in selectedUserSaldo"
            :key="index"
          >
            <v-card-text style="color: black; border: 1px solid #570c22">
              <p>Data: {{ converterData(deposit.date_created) }}</p>
              <p>
                Valor: {{  (Math.trunc(deposit.value * 100) / 100).toLocaleString(
                    'pt-BR',
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }
                  ) }}
                {{ deposit.value > 1 ? 'Mezzo Coins' : 'Mezzo Coin' }}
              </p>
            </v-card-text>
          </v-card>
        </div>
      </div>

      <div
        class="d-flex flex-column align-center"
        style="width: 33%; padding: 20px"
      >
        <h4 style="font-family: poppins; color: #570c22">
          Tipo de transação: Retirada
        </h4>
        <div style="height: 20rem; overflow-y: auto">
          <v-card
            style="box-shadow: none"
            v-for="(withdraw, index) in selectedUserDespesa"
            :key="index"
          >
            <v-card-text style="border: 1px solid #570c22; color: black">
              <p>Data: {{ converterData(withdraw.date_created) }}</p>
              <p>
                Valor:
                {{
                  (Math.trunc(withdraw.value * 100) / 100).toLocaleString(
                    'pt-BR',
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }
                  )
                }}
                {{ withdraw.value > 1 ? 'Mezzo Coins' : 'Mezzo Coin' }}
              </p>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import * as dayjs from 'dayjs';

// vuejs data
export default {
  name: 'ClienteHistorico',
  props: {
    selectedUser: Object,
    selectedUserDesconto: Array,
    selectedUserSaldo: Array,
    selectedUserDespesa: Array
  },
  data() {
    return {};
  },
  methods: {
    closeDialog() {
      this.$emit('close');
    },
    converterData(date) {
      const dateFormatted = dayjs(date).format('DD/MM/YYYY [às] HH:mm:ss');
      return dateFormatted;
    }
  },
  mounted() {
    console.log(this.selectedUserDesconto);
  }
};
</script>

